export const defaultValues = {
  company_name: '',
  company_url: '',
  phone: '',
  email: '',
  first_name: '',
  last_name: '',
  specialist: '',
  position: '',
  message: '',
  hpot: ''
};
